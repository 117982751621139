<template>
  <div class="xeRealtime heightMAX">
    <div class="row" v-xe-pm.E v-show="$store.getters.showErrorPoint && errorPoints.length">
      <div class="col-md-12">
        존재하지 않는 관제점
      </div>
      <div class="col-md-12">
        {{ errorPoints }}
      </div>
    </div>

    <panel
      v-if="(widget.panel && widget.panel.display) || isEditMode"
      :title="$t(widget.title)"
      :variant="panelVariant"
      bodyClass="p-0 chartHeightMax"
      hideExpand="false"
      hideReload="true"
      hideCollapse="true"
      :hideRemove="isEditMode ? 'false' : 'true'"
      :showSetting="isEditMode ? 'true' : 'false'"
      @panel-setting="onPanelSetting"
      @panel-remove="onPanelRemove"
      @panel-expand="onPanelExpand"
      ref="panel"
      :fixedHeight="fixedHeight"
    >
      <template slot="header">
        <drag class="cursor-pointer" :transfer-data="{ item: widget }">
          <drop @dragover="handleTitleDragOver(...arguments)" @drop="handleTitleDrop(...arguments)">
            <h4 class="panel-title">{{ $t(widget.title) }}</h4>
          </drop>
        </drag>
      </template>

      <drop @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
        <div v-if="isShow" class="realTimeContentHeight">
          <xe-html-component ref="rawHtmlComponent" v-if="rawHtml" :is="rawHtmlComponent"></xe-html-component>
        </div>
            <button v-if="isEditMode" class="btn btn-default" @click="openPopupDebug">{{$t('디버그창')}}</button>
      </drop>
    </panel>

    <drop v-else @dragover="handleBodyDragOver(...arguments)" @drop="handleBodyDrop(...arguments)" class="heightMAX">
      <div v-if="isShow" class="bg-white m-b-20 realTimeContentHeight">
        <h4 v-if="widget.title">{{ $t(widget.title) }}</h4>
        <xe-html-component ref="rawHtmlComponent" v-if="rawHtml" :is="rawHtmlComponent"></xe-html-component>
      </div>
          <button v-if="isEditMode" class="btn btn-default" @click="openPopupDebug">{{$t('디버그창')}}</button>
    </drop>

    <b-modal ref="modalSetting" size="lg" title="설정 - RealTime" bodyClass="p-t-0 p-b-0" hide-footer>
      <xe-realtime-setting :initSetting="realtimeSetting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
    </b-modal>

    <b-modal ref="popupFacility" cancel-variant="default" :title="popupObj.popupType === 'Facility' ? popupSpec.facilityName : ''">
      <xe-realtime-facility-modal :popupObj="popupObj" :popupProps="popupProps" />
    </b-modal>

    <b-modal ref="popupDebug" cancel-variant="default" :title="$t('디버그창')" size="lg">
      <div class="row form-row">
        <textarea class="form-control" rows="20" :value="JSON.stringify(widget.parts, null, 2)" />
      </div>
      <div class="row form-row">
        <textarea class="form-control" rows="20" :value="JSON.stringify(widget.objects, null, 2)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import backEndApi from "@api/backEndApi.js";
import xeBaseWidget from "./xeBaseWidget.js";
import xeRealtimeSetting from "@src/views/widget/v1/setting/xeRealtimeSetting.vue";
import xeRealtimeFacilityModal from "@src/views/widget/v1/setting/xeRealtimeFacilityModal.vue";
import * as popupMessages from "@src/consts/popupMessageConsts"

export default {
  name: "xeRealtime",
  extends: xeBaseWidget,
  components: {
    xeRealtimeSetting,
    xeRealtimeFacilityModal,
  },
  data() {
    return {
      rawHtml: "",
      popupObj: { propertyName: "", popupType: "", facility: { facilityIdx: null, properties: [] } }, // popupType= 없음, Property, Facility
      popupSpec: {}, // facility
      popupProps: {}, // facilityMap

      liveAM: {},
      errorPoints: [],
    };
  },
  computed: {
    realtimeSetting() {
      return JSON.parse(JSON.stringify(this.widget));
    },
    rawVueComponent() {
      return new Function(parent.rawVue)();
    },
    rawHtmlComponent() {
      let parent = this;
      return {
        name: "raw-html-component",
        template: parent.rawHtml,
        data() {
          return {
            objects: parent.widget.objects,
            parts: parent.widget.parts,
            cur: null,
            changeArr: [],
            newInput: null,
            loading: false,
          };
        },
        watch: {
          liveDate() {
            this.bindLiveReal();
          },
        },
        computed: {
          liveDate() {
            return parent.liveDate;
          },
        },
        mounted() {
          this.bindLiveReal();

          let realtimes = document.getElementsByClassName("xeRealtime");

          for(let i = 0; i < realtimes.length; i++){
            realtimes[i].parentNode.style.height = "100%";
          }

        },
        methods: {
          bindLiveReal() {
            this.$set(this, "loading", true);

            setTimeout(() => {
              this._bindLiveReal();

              setTimeout(() => {
                this.$set(this, "loading", false);
              }, 700);
            }, 100);
          },
          _bindLiveReal() {
            let liveReal = this.readStorage("liveReal");
            // console.log("raw-html receive monitor =", JSON.stringify(liveReal));

            if (this.objects && Array.isArray(this.objects)) {
              this.objects.forEach((obj) => {
                // 부모개체에 사용중인 주소 알려주기
                parent.appendUsePoint(obj.ptAddr);

                let ptAddr = Object.keys(liveReal).find((v) => v === obj.ptAddr);
                if (this.isEmpty(ptAddr)) {
                  obj.ptVal = "-";
                  obj.regDt = "-";
                } else {
                  let tr = liveReal[ptAddr];

                  if (tr.ptVal && tr.ptVal != null && tr.ptVal != undefined && this.isNumeric(tr.ptVal)) {
                    obj.ptVal = tr.ptVal;
                    obj.regDt = tr.regDt;
                    this.$forceUpdate();
                  }
                }
              });
            }

            if (this.parts && Array.isArray(this.parts)) {
              // 모든 객체 탐색
              this.bindPartsVal(
                this.parts,
                function(key) {
                  return key === "ptAddr";
                },
                liveReal
              );
            }
          },
          // visual 파일에서 ptAddr 객체만 탐색
          bindPartsVal(obj, predicate, liveReal) {
            for (let p in obj) {
              if (typeof obj[p] == "object") {
                this.bindPartsVal(obj[p], predicate, liveReal);
              } else if (predicate(p, obj[p])) {
                if (obj.ptAddr !== "") {
                  // 부모개체에 사용중인 주소 알려주기
                  parent.appendUsePoint(obj.ptAddr);

                  let ptAddr = Object.keys(liveReal).find((v) => v === obj.ptAddr);
                  if (this.isEmpty(ptAddr)) {
                    obj.ptVal = "-";
                    obj.regDt = "-";
                  } else {
                    let tr = liveReal[ptAddr];

                    if (tr.ptVal != null && tr.ptVal != undefined && this.isNumeric(tr.ptVal)) {
                      obj.ptVal = tr.ptVal.toFixed(1); // 소수점 자리수 작업 하드코딩임...
                      obj.regDt = tr.regDt;
                      this.$forceUpdate();
                    }
                  }
                }
              }
            }
          },
          openPopup(obj) {
            //console.log("openPopup", obj);
            parent.openPopup(obj);
          },
          // SVG상 포지션 가져오기
          showCoords(event) {
            console.log("X: ", event.offsetX, " , Y: ", event.offsetY);
          },
          writePt(curs) {
            if (!Array.isArray(curs)) curs = [curs];
            let copyCurs = JSON.parse(JSON.stringify(curs));

            // outputVal이 존재할때 outputVal을 cur.ptVal로 변경한다.(onApplyProperty을 하기 위해서임.)
            copyCurs.forEach((cur) => {
              if (!cur.outputVal) cur.outputVal = cur.ptVal;
            });

            parent.onApplyProperty(copyCurs);
          },
          notiToHtml(text) {
            this.alertNoti(this.$t(text));
          },
        },
      };
    },
    popupProp() {
      return this.popupProps[this.popupObj.propertyName] || {};
    },
  },
  watch: {
    "widget.fileName": function() {
      // console.log("Html watch widget", newV);
      this.searchRawHtml(this.widget.fileName);
    },
    liveDate() {
      this.bindLiveReal();
    },
  },
  created() {
    // 초기값 설정
    if (!this.widget.fileName) {
      this.widget.fileName = "";
    }
    if (!this.widget.objects) {
      this.widget.objects = [];
    }
  },
  methods: {
    initSetting() {
      this.liveAM = this.readStorage("liveAM");

      this.searchRawHtml(this.widget.fileName);
    },
    searchRawHtml(fileName) {
      if (this.isEmpty(fileName)) {
        this.rawHtml = "<h1>Empty</h1>";
        this.isShow = true;
        return;
      }

      this.isShow = false;
      backEndApi.visual.getRealtime(fileName).then(({ data }) => {
        if (this.$err(data)) return;

        this.rawHtml = "" + data;
        this.isShow = true;
      });
    },
    // 사용중인 주소 모으기
    appendUsePoint(useAddr) {
      if (this.errorPoints.filter((v) => v == useAddr).length == 0 && this.isEmpty(this.liveAM[useAddr])) {
        this.errorPoints.push(useAddr);
      }
    },
    bindData(newLogs) {
      // 조회결과 바인딩
      console.log("bindData", newLogs);

      // 조회된 경우, live 바인딩을 끊고, 조회값으로 대체해야 함...
    },
    bindLiveReal() {
      let liveReal = this.readStorage("liveReal");
      this.popupObj.ptVal = liveReal[this.popupObj.ptAddr] ? liveReal[this.popupObj.ptAddr].ptVal : "?";

      Object.keys(this.popupProps).map((v) => {
        let ptAddr = this.popupProps[v].ptAddr;
        let ptVal = liveReal[ptAddr] ? liveReal[ptAddr].ptVal : "-";
        if (this.popupProps[v].ptVal !== ptVal) this.popupProps[v] = Object.assign(this.popupProps[v], { ptVal: ptVal });
      });
    },
    openPopup(obj) {
      // 초기값 바인딩
      this.popupObj.facility.properties = [];
      this.popupObj.propertyName = obj.propertyName;
      this.popupObj.popupType = obj.popupType;

      backEndApi.facilityMap.getFacilityMapByPtAddr(obj.ptAddr).then(({ data }) => {
        if (this.isEmpty(data)) {
          console.log(`${obj.ptAddr} 장비맵핑속성이 존재하지 않습니다.`);
          return;
        }

        if (this.$err(data)) return;

        // facilityIdx의 조회 오류를 줄이고자 한번 조회하고 해당 포인트에 맞는 정보로 교체
        let facilityIdx = data.facilityIdx || obj.facilityIdx;
        let propertyName = data.propertyName;

        // popuptype이 None이 아니면서 등록된 장비가 없다면 제어 안되는 팝업임
        if (facilityIdx == null) {
          this.alertNoti(popupMessages.XE_CONTROL_EQUIP_POPUP_MESSAGE);
          return;
        }

        backEndApi.facility.getFacility(facilityIdx).then(({ data }) => {
          if (this.$err(data)) return;

          // this.popupObj.facility 정보 채우기
          this.popupObj.facility = Object.assign(this.popupObj.facility, data);

          backEndApi.facilityMap
            .searchFacilityMap(this.popupObj.facility.facilityIdx)
            .then(({ data }) => {
              if (this.$err(data)) return;

              let facilityMap = data;

              if (this.popupObj.popupType === "Property") {
                let inputProp = facilityMap.find((prop) => prop.direction === "In" && prop.propertyName === propertyName);
                let outputProp = facilityMap.find((prop) => prop.direction === "Out" && prop.propertyName === propertyName);

                if (!this.isEmpty(outputProp)) this.popupObj.facility.properties.push(Object.assign(inputProp, { writeable: true, outputPtAddr: outputProp.ptAddr, wtVal: null, ptVal: null, valueType: "analog" }));
                else this.popupObj.facility.properties.push(Object.assign(inputProp, { writeable: false, outputPtAddr: "", wtVal: null, ptVal: null, valueType: "analog" }));
              } else {
                facilityMap
                  .filter((v) => v.direction === "In")
                  .map((propertie) => {
                    let prop = facilityMap.find((prop) => prop.direction === "Out" && prop.propertyName === propertie.propertyName);

                    if (!this.isEmpty(prop)) this.popupObj.facility.properties.push(Object.assign(propertie, { writeable: true, outputPtAddr: prop.ptAddr, wtVal: null, ptVal: null, valueType: "analog" }));
                    else this.popupObj.facility.properties.push(Object.assign(propertie, { writeable: false, outputPtAddr: "", wtVal: null, ptVal: null, valueType: "analog" }));
                  });
              }
            })
            .then(() => {
              //console.log("this.popupObj.facility >>>>>>>>>>>>>> ", this.popupObj.facility);
              this.$refs.popupFacility.show();
            });
        });
      });
    },
    openPopup_(obj) {
      this.popupObj = JSON.parse(JSON.stringify(Object.assign({}, obj)));

      if (this.popupObj.popupType === "None") {
        return;
      }

      // 해당 포인트의 FacilityMap 정보 가져오기
      // popupObj: { propertyName: "", popupType: "", facility: { facilityIdx: null, properties: [] } }
      backEndApi.facilityMap.getFacilityMapByPtAddr(this.popupObj.ptAddr).then(({ data }) => {
        if (this.$err(data)) return;

        this.popupObj = Object.assign(this.popupObj, data);

        // 연동된 장비가 없는 경우
        if (this.isEmpty(this.popupObj.facilityIdx)) {
          console.log("장비없음");
        }

        backEndApi.facility.getFacility(this.popupObj.facilityIdx).then(({ data }) => {
          if (this.$err(data)) return;

          this.popupSpec = data;
          // console.log("facility popupSpec", this.popupSpec);

          backEndApi.facilityMap.searchFacilityMap(this.popupObj.facilityIdx).then(({ data }) => {
            if (this.$err(data)) return;

            let facilityMap = data;

            let liveAM = this.readStorage("liveAM");

            // Input에 매칭되는 Out 관제점 찾기 -- facilityIdx, direction, propertyName 키값
            facilityMap
              .filter((v) => v.direction === "In")
              .map((v) => {
                let prop = facilityMap.filter((facility) => facility.direction === "Out" && facility.propertyName === v.propertyName);
                if (prop.length == 1) this.popupProps[v.propertyName] = Object.assign(v, { writeable: true, outputPtAddr: prop[0].ptAddr, wtVal: null, ptVal: null, valueType: "analog" }, this.popupSpec);
                else this.popupProps[v.propertyName] = Object.assign(v, { writeable: false, outputPtAddr: "", wtVal: null, ptVal: null, valueType: "analog" }, this.popupSpec);

                this.popupProps[v.propertyName].valueType = liveAM[v.ptAddr].valueType;
              });
            this.$refs.popupFacility.show();
          });
        });
      });
    },
    onApplyProperty(curs) {
      curs.forEach((cur) => {
        if (!this.isEmpty(cur.ptAddr) && cur.outputVal != null && cur.outputVal != "-") {
          // TODO: 트랜잭션... 멀티처리...
          backEndApi.facility.getFacilityByPtAddr(cur.ptAddr).then(({ data }) => {
            if (this.$err(data)) return;

            let facility = null;

            if (Array.isArray(data) && data.length > 0) facility = data[0];

            if (!this.isEmpty(facility) && !this.isEmpty(facility.outputPtAddr)) {
              let now = new Date().normalize(6);
              let ptVal = typeof cur.outputVal === "string" ? parseFloat(cur.outputVal) : cur.outputVal;

              if (ptVal != null && !isNaN(ptVal)) {
                let output = {
                  channelIdx: facility.channelIdx,
                  facilityIdx: facility.parentFacilityIdx || facility.facilityIdx,
                  ptAddr: facility.outputPtAddr,
                  ptVal: ptVal,
                  setDt: now,
                  state: "init",
                  updDt: now,
                };

                backEndApi.outputLog.insertOutputLog(output).then(({ data }) => {
                  if (this.$err(data)) return;

                  this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
                });
              }
            }
          });
        }
      });
    },
    onCheckProperty(obj, prop) {
      console.log("onCheckProperty", obj, prop);
    },
    // dnd
    handleDropPoint(transfer) {
      this.isShow = false;

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // objects : [ { "type":"info", "label": "Temperature1",      "point": "084000001_0000_0000_VLI_00000001", "unit":"℃", "x":213, "y":285, "css":"xe-info-text-10" } ]
      // objects: that.widget.objects,

      let defaultV = { type: "info", label: "", point: "", unit: "", x: 113, y: 185, css: "xe-info-text-10" };

      // TODO: 위치값은 ??

      if (transfer.item.type === this.CODE.Drag.PointGroup) {
        transfer.item.points.map((point) => {
          this.widget.objects.push(Object.assign(defaultV, { label: point.title, point: point.ptAddr }));
        });
      } else {
        let point = transfer.item;
        this.widget.objects.push(Object.assign(defaultV, { label: point.title, point: point.ptAddr }));
      }
      this.initSetting();
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      Vue.nextTick(() => {
        this.isShow = true;
      });
    },

    openPopupDebug() {
      this.$refs.popupDebug.show();
    },
    closePopupDebug() {
      this.$refs.popupDebug.hide();
    },
  },
};
</script>


<style scoped>
.realTimeContentHeight{
  height: calc(100% - 34px);
}
</style>